import React from 'react';

import Wrapper from "../components/Wrapper";
import ErrorPage from "../pages/ErrorPage";

import {
  Page,
  Grid,
  Card,
  StatsCard,
  StampCard,
  ProgressCard,
  Loader,
  Header,
  Text,
  colors
} from 'tabler-react';

import C3Chart from "react-c3js";

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      dashboardStats: null,
      loading: true,
      error: null
    }
  }

  async componentDidMount() {
    try {
      const res = await fetch(`https://staging.api.qualityexpressvtc.eu/dashboardStats`, { credentials: 'include' });
      if (res.status !== 200) {
        this.setState({ error: res.status });
      } else {
        const fetched = await res.json();
        this.setState({ dashboardStats: fetched, loading: false });
      }
    } catch (err) {
      console.error(err);
      this.setState({ error: err.toString() });
    }
  }

  render() {
    if (this.state.error) {
      return <ErrorPage err={this.state.error} />
    } else {
      const stats = this.state.dashboardStats;
      return (
        <Wrapper>
          <Page.Content title="Dashboard">
            {this.state.loading ? <Loader /> : <>
              <Grid.Row cards>
                <Grid.Col width={12} lg={6}>
                  <Grid.Row cards>
                    <Grid.Col width={12} lg={6}>
                      <StatsCard
                        layout={2}
                        movement={stats.company.jobs.percentageChange ? stats.company.jobs.percentageChange : 100}
                        total={stats.company.jobs.history[0]}
                        label="Jobs logged today"
                        chart={
                          <C3Chart
                            style={{ height: "100%" }}
                            padding={{
                              bottom: -10,
                              left: -1,
                              right: -1,
                            }}
                            data={{
                              names: {
                                data1: "Jobs logged",
                              },
                              columns: [
                                [
                                  "data1",
                                  stats.company.jobs.history[6],
                                  stats.company.jobs.history[5],
                                  stats.company.jobs.history[4],
                                  stats.company.jobs.history[3],
                                  stats.company.jobs.history[2],
                                  stats.company.jobs.history[1],
                                  stats.company.jobs.history[0]
                                ]
                              ],
                              type: "area",
                            }}
                            legend={{
                              show: false,
                            }}
                            transition={{
                              duration: 0,
                            }}
                            point={{
                              show: false,
                            }}
                            tooltip={{
                              format: {
                                title: function (x) {
                                  return "";
                                },
                              },
                            }}
                            axis={{
                              y: {
                                padding: {
                                  bottom: 0,
                                },
                                show: false,
                                tick: {
                                  outer: false,
                                },
                              },
                              x: {
                                padding: {
                                  left: 0,
                                  right: 0,
                                },
                                show: false,
                              },
                            }}
                            color={{
                              pattern: ["#467fcf"],
                            }}
                          />
                        }
                      />
                    </Grid.Col>
                    <Grid.Col width={12} lg={6}>
                      <StatsCard
                        layout={2}
                        movement={stats.company.distance.percentageChange ? stats.company.distance.percentageChange : 100}
                        total={`${stats.company.distance.history[0]} km`}
                        label="Distance logged today"
                        chart={
                          <C3Chart
                            style={{ height: "100%" }}
                            padding={{
                              bottom: -10,
                              left: -1,
                              right: -1,
                            }}
                            data={{
                              names: {
                                data1: "Kilometers logged",
                              },
                              columns: [
                                [
                                  "data1",
                                  stats.company.distance.history[6],
                                  stats.company.distance.history[5],
                                  stats.company.distance.history[4],
                                  stats.company.distance.history[3],
                                  stats.company.distance.history[2],
                                  stats.company.distance.history[1],
                                  stats.company.distance.history[0]
                                ]
                              ],
                              type: "area",
                            }}
                            legend={{
                              show: false,
                            }}
                            transition={{
                              duration: 0,
                            }}
                            point={{
                              show: false,
                            }}
                            tooltip={{
                              format: {
                                title: function (x) {
                                  return "";
                                },
                              },
                            }}
                            axis={{
                              y: {
                                padding: {
                                  bottom: 0,
                                },
                                show: false,
                                tick: {
                                  outer: false,
                                },
                              },
                              x: {
                                padding: {
                                  left: 0,
                                  right: 0,
                                },
                                show: false,
                              },
                            }}
                            color={{
                              pattern: ["#5eba00"],
                            }}
                          />
                        }
                      />
                    </Grid.Col>
                    <Grid.Col width={12} lg={6}>
                      <StampCard
                        color="blue"
                        icon="box"
                        header={
                          <>
                            {stats.user.jobs.total} <small>jobs logged by you</small>
                          </>
                        }
                        footer={`${stats.user.jobs.today} today`}
                      />
                    </Grid.Col>
                    <Grid.Col width={12} lg={6}>
                      <StampCard
                        color="green"
                        icon="globe"
                        header={
                          <>
                            {stats.user.distance.total} km <small>driven by you</small>
                          </>
                        }
                        footer={`${stats.user.distance.today} km today`}
                      />
                    </Grid.Col>
                    <Grid.Col width={12} lg={6}>
                      <ProgressCard
                        header={`Your progress to monthly required ${stats.minimumMonthlyDistance} km`}
                        content={`${stats.user.distance.thisMonth} km`}
                        progressColor={(stats.user.distance.thisMonth / stats.minimumMonthlyDistance * 100 >= 100) ? "green" :
                          (stats.user.distance.thisMonth / stats.minimumMonthlyDistance * 100 >= 80) ? "yellow" :
                            (stats.user.distance.thisMonth / stats.minimumMonthlyDistance * 100 >= 60) ? "orange" : "red"}
                        progressWidth={stats.user.distance.thisMonth / stats.minimumMonthlyDistance * 100}
                      />
                    </Grid.Col>
                  </Grid.Row>
                </Grid.Col>
                <Grid.Col width={12} lg={6}>
                  <Grid.Row>
                    <Grid.Col width={12} lg={6}>
                      <Card>
                        <Card.Body className="p-3 text-center">
                          <Header className="m-0 mt-4">{"£" + stats.company.balance.toLocaleString()}</Header>
                          <Text color="muted" className=" mb-4">
                            in company bank
                          </Text>
                        </Card.Body>
                      </Card>
                    </Grid.Col>
                    <Grid.Col width={12} lg={6}>
                      <Card>
                        <Card.Body className="p-3 text-center">
                          <Header className="m-0 mt-4">{"£" + stats.user.balance.toLocaleString()}</Header>
                          <Text color="muted" className=" mb-4">
                            in your wallet
                          </Text>
                        </Card.Body>
                      </Card>
                    </Grid.Col>
                    <Grid.Col width={12} lg={6}>
                      <Card>
                        <Card.Header>
                          <Card.Title>Job types in the past 7 days</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <C3Chart
                            style={{ height: "12rem" }}
                            data={{
                              columns: [
                                // each columns data
                                ["data1", stats.company.charts.type.multiplayerPercentage],
                                ["data2", stats.company.charts.type.singleplayerPercentage],
                              ],
                              type: "donut", // default type of chart
                              colors: {
                                data1: colors["green"],
                                data2: colors["green-light"],
                              },
                              names: {
                                // name of each serie
                                data1: "Multiplayer",
                                data2: "Singleplayer",
                              },
                            }}
                            legend={{
                              show: false, //hide legend
                            }}
                            padding={{
                              bottom: 0,
                              top: 0,
                            }}
                          />
                        </Card.Body>
                      </Card>
                    </Grid.Col>
                    <Grid.Col width={12} lg={6}>
                      <Card>
                        <Card.Header>
                          <Card.Title>Job markets in the past 7 days</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <C3Chart
                            style={{ height: "12rem" }}
                            data={{
                              columns: [
                                // each columns data
                                ["data1", stats.company.charts.market.quickJobPercentage],
                                ["data2", stats.company.charts.market.cargoMarketPercentage],
                                ["data3", stats.company.charts.market.freightMarketPercentage],
                                ["data4", stats.company.charts.market.externalMarketPercentage],
                                ["data5", stats.company.charts.market.externalContractsPercentage],
                              ],
                              type: "pie", // default type of chart
                              colors: {
                                data1: colors["blue-lighter"],
                                data2: colors["blue-light"],
                                data3: colors["blue"],
                                data4: colors["blue-dark"],
                                data5: colors["blue-darker"],
                              },
                              names: {
                                // name of each serie
                                data1: "Quick Job",
                                data2: "Cargo Market",
                                data3: "Freight Market",
                                data4: "External Market",
                                data5: "External Contracts",
                              },
                            }}
                            legend={{
                              show: false, //hide legend
                            }}
                            padding={{
                              bottom: 0,
                              top: 0,
                            }}
                          />
                        </Card.Body>
                      </Card>
                    </Grid.Col>
                  </Grid.Row>
                </Grid.Col>
              </Grid.Row>
            </>}
          </Page.Content>
        </Wrapper>
      );
    }
  }

}

export default Home;