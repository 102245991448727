import React from "react";

import Wrapper from "../../components/Wrapper";

import { Page, Card, Form, Button, Loader, Container, Header } from "tabler-react";
import ErrorPage from "../ErrorPage";

class Admin extends React.Component {
  constructor() {
    super();
    this.state = {
      error: null,
      loading: true,
      data: null,
      success: false
    };
  }

  async componentDidMount() {
    try {
      const res = await fetch(`https://staging.api.qualityexpressvtc.eu/settings`, {
        credentials: "include",
      });
      if (res.status !== 200) {
        this.setState({ error: res.status });
      } else {
        const fetched = await res.json();
        this.setState({ data: fetched, loading: false });
      }
    } catch (err) {
      console.error(err);
      this.setState({ error: err.toString() });
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    console.log(e.target[0].name);
    console.log(e.target[0].value);
    let newSettings;
    if (e.target[0].name === "companyBalance") {
      newSettings = {
        ...this.state.data,
        companyBalance: parseFloat(e.target[0].value),
      };
    } else {
      newSettings = {
        ...this.state.data,
        globalSettings: {
          ...this.state.data.globalSettings,
          [e.target[0].name]: parseFloat(e.target[0].value),
        },
      };
    }
    try {
      const res = await fetch(`https://staging.api.qualityexpressvtc.eu/settings`, {
        method: "PUT",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newSettings),
      });
      if (res.status !== 200) {
        this.setState({ error: `Error ${res.status}: ${res.statusText}` });
      } else {
        this.setState({ success: true, data: newSettings });
      }
    } catch (err) {
      console.error(err);
      this.setState({ error: err.toString() });
    }
  };

  render() {
    if (this.state.error) {
      return <ErrorPage err={this.state.error} />;
    } else if (this.state.success) {
      return (
        <Wrapper>
          <Page.Content title="VTC Administration • Settings" className="text-center">
        <Container>
          <i class="fe fe-check-circle" style={{ fontSize: "100px" }} />
          <Header.H1 className="display-1 text-muted mb-2 mt-1">
            Success
          </Header.H1>
          <Header.H3>Your settings modifications were successfully saved.</Header.H3>
          <Header.H4 className="text-muted font-weight-normal">
            Refresh this page if you wish to continue editing.
          </Header.H4>
        </Container>
        </Page.Content>
        </Wrapper>
      );
    } else {
      return (
        <Wrapper>
          <Page.Content title="VTC Administration • Settings">
            {this.state.loading ? (
              <Loader />
            ) : (
              <>
                <Card>
                  <Card.Header>
                    <Card.Title>Financials</Card.Title>
                  </Card.Header>
                  {this.state.addedAlert && (
                    <Card.Alert color="success">
                      Successfully updated
                    </Card.Alert>
                  )}
                  {this.state.errorAlert && (
                    <Card.Alert color="danger">
                      {this.state.errorAlert}
                    </Card.Alert>
                  )}
                  <Card.Alert color="warning">
                    Only input clean numeric values. Use dot (.) for decimals, not comma (,)
                  </Card.Alert>
                  <Card.Body>
                    <Form onSubmit={this.handleSubmit}>
                      <Form.Label>Company Bank Balance</Form.Label>
                      <Form.InputGroup
                        append={<Button color="primary">Update</Button>}
                      >
                        <Form.Input
                          name="companyBalance"
                          defaultValue={this.state.data.companyBalance}
                        />
                      </Form.InputGroup>
                    </Form>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Header>
                    <Card.Title>Technical Settings</Card.Title>
                  </Card.Header>
                  <Card.Alert color="danger">
                    <strong>Do not modify these values unless are absolutely 100%
                    certain you know what you're doing!</strong>
                  </Card.Alert>
                  <Card.Alert color="warning">
                    Only input clean numeric values. Use dot (.) for decimals, not comma (,)
                  </Card.Alert>
                  {this.state.addedAlert && (
                    <Card.Alert color="success">
                      Successfully updated
                    </Card.Alert>
                  )}
                  {this.state.errorAlert && (
                    <Card.Alert color="danger">
                      {this.state.errorAlert}
                    </Card.Alert>
                  )}
                  <Card.Body>
                    {Object.entries(this.state.data.globalSettings).map(
                      ([key, value]) => (
                        <Form onSubmit={this.handleSubmit} key={key}>
                          <Form.Label>{key}</Form.Label>
                          <Form.InputGroup
                            append={<Button color="primary">Update</Button>}
                          >
                            <Form.Input name={key} defaultValue={value} />
                          </Form.InputGroup>
                        </Form>
                      )
                    )}
                  </Card.Body>
                </Card>
              </>
            )}
          </Page.Content>
        </Wrapper>
      );
    }
  }
}

export default Admin;
