import React from "react";
import { Button, Card, ProgressCard, Dimmer } from "tabler-react";
import ConditionalWrapper from "./ConditionalWrapper";


function getMonth(monthIndex) {
    const monthsShort = [
        "Jan", "Feb", "Mar", "Apr",
        "May", "Jun", "Jul", "Aug",
        "Sep", "Oct", "Nov", "Dec"
    ];
    const monthsLong = [
        "January", "February", "March", "April",
        "May", "June", "July", "August",
        "September", "October", "November", "December"
    ];

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    // Calculate the target month's index
    const targetMonthIndex = currentMonth - monthIndex;

    // Calculate the target year and month
    const targetYear = Math.floor(targetMonthIndex / 12) + currentYear;
    const targetMonth = (targetMonthIndex % 12 + 12) % 12; // Ensure positive index

    // Build the result string
    let result;

    // Include the year in the string only if it's different from the current year
    if (targetYear !== currentYear) {
        result = monthsShort[targetMonth]
        result += ` ${targetYear}`;
    } else {
        result = monthsLong[targetMonth];
    }

    return result;
}

class MonthlyProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: props.userData,
      loading: false,
      error: null,
      monthIndex: 0,
    };
  }

  /* async componentDidMount() {
    const { driverID } = this.props;

    try {
      const res = await fetch(`https://staging.api.qualityexpressvtc.eu/users/${driverID}?driverID=${driverID}`, { credentials: 'include' });
      if (res.status !== 200) {
        this.setState({ error: res.status });
      } else {
        const fetched = await res.json()
        this.setState({ userData: fetched, loading: false })
      }
    } catch (err) {
      console.error(err);
      this.setState({ error: err.toString() });
    }
  } */

  fetchProgress = () => {
    fetch(
      `https://staging.api.qualityexpressvtc.eu/users/${this.props.driverID}?monthIndex=${this.state.monthIndex}`,
      { credentials: "include" }
    )
      .then((res) => res.json())
      .then((fetched) => this.setState({ userData: fetched, loading: false }))
      .catch((err) => {
        console.error(err);
        this.setState({ error: err.toString() });
      });
  };

  onButtonClick = (direction) => {
    if (direction === "left") {
      this.setState({ monthIndex: this.state.monthIndex + 1, loading: true }, () => this.fetchProgress());
    } else if (direction === "right") {
      this.setState({ monthIndex: this.state.monthIndex - 1, loading: true }, () => this.fetchProgress());
    } else if (direction === "reset") {
      this.setState({ monthIndex: 0, loading: true }, () => this.fetchProgress());
    }
  };

  render() {
    return (
      <Card>
        <Card.Header>
          <Card.Title>Monthly Progress</Card.Title>
        </Card.Header>
        <Card.Alert color="warning">
          This is only visible to HR staff & Management.
        </Card.Alert>
        <Card.Body>
          <ConditionalWrapper
            condition={this.state.loading === true}
            wrapper={(children) => (
              <Dimmer active loader>
                {children}
              </Dimmer>
            )}
          >
            <ProgressCard
              header={`Progress to monthly required ${this.state.userData.distance.monthlyMinimum} km`}
              content={`${this.state.userData.distance.thisMonth} km`}
              progressColor={
                (this.state.userData.distance.thisMonth /
                  this.state.userData.distance.monthlyMinimum) *
                  100 >=
                100
                  ? "green"
                  : (this.state.userData.distance.thisMonth /
                      this.state.userData.distance.monthlyMinimum) *
                      100 >=
                    80
                  ? "yellow"
                  : (this.state.userData.distance.thisMonth /
                      this.state.userData.distance.monthlyMinimum) *
                      100 >=
                    60
                  ? "orange"
                  : "red"
              }
              progressWidth={
                (this.state.userData.distance.thisMonth /
                  this.state.userData.distance.monthlyMinimum) *
                100
              }
            />
            <ProgressCard
              header={`Average damage`}
              content={`${this.state.userData.damage.thisMonth}%`}
              progressColor={
                (this.state.userData.damage.thisMonth /
                  this.state.userData.damage.monthlyMaximum) *
                  100 >=
                200
                  ? "red"
                  : (this.state.userData.damage.thisMonth /
                      this.state.userData.damage.monthlyMaximum) *
                      100 >=
                    125
                  ? "orange"
                  : (this.state.userData.damage.thisMonth /
                      this.state.userData.damage.monthlyMaximum) *
                      100 >=
                    100
                  ? "yellow"
                  : "green"
              }
              progressWidth={
                (this.state.userData.damage.thisMonth /
                  this.state.userData.damage.monthlyMaximum) *
                100
              }
            />
          </ConditionalWrapper>
        </Card.Body>
        <Card.Footer>
          <Button.List align="center">
            <Button
              color="primary"
              size="sm"
              icon="arrow-left"
              onClick={() => this.onButtonClick("left")}
            >
              {getMonth(this.state.monthIndex + 1)}
            </Button>
            <Button
              outline
              color="secondary"
              size="sm"
              disabled
              RootComponent="a"
            >
              {getMonth(this.state.monthIndex)}
            </Button>
            {this.state.monthIndex > 0 && (
            <Button
              color="primary"
              size="sm"
              icon="arrow-right"
              onClick={() => this.onButtonClick("right")}
            >
              {getMonth(this.state.monthIndex - 1)}
            </Button>
            )}
            {this.state.monthIndex > 1 && (
              <Button
                color="orange"
                size="sm"
                icon="corner-up-right"
                onClick={() => this.onButtonClick("reset")}
              >
                {getMonth(0)}
              </Button>
            )}
            )}
          </Button.List>
        </Card.Footer>
      </Card>
    );
  }
}

export default MonthlyProgress;
