import React from 'react';

import Wrapper from "../../components/Wrapper";

import {
  Page,
  Card,
  Button,
  Form,
  Alert,
  Table
} from 'tabler-react';
import ConfirmButton from '../../components/ConfirmButton';
import ConditionalWrapper from '../../components/ConditionalWrapper';

import userUtils from '../../services/userUtils';

const currentUser = userUtils.getUser();
const checkEditPerm = (userToEditPerm) => {
  if (userUtils.permissionList.indexOf(currentUser.permLevel) > userUtils.permissionList.indexOf(userToEditPerm)) {
    return true;
  } else {
    return false;
  }
}

class AdminUsers extends React.Component {
  constructor() {
    super();
    this.state = {
      addedAlert: false,
      errorAlert: null,
      users: [],
      editMode: null
    }
  }

  onUserRemove = async (id) => {
    try {
      const res = await fetch(`https://staging.api.qualityexpressvtc.eu/users/${id}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      if (res.status !== 200) {
        this.setState({ errorAlert: `Error ${res.status}: ${res.statusText}` });
        window.scrollTo(0, 0);
      } else {
        this.fetchUsers();
      }
    } catch (err) {
      console.error(err);
      this.setState({ errorAlert: err.toString() });
      window.scrollTo(0, 0);
    }
  }

  fetchUsers = () => {
    fetch(`https://staging.api.qualityexpressvtc.eu/users`, { credentials: 'include' })
      .then(res => res.json())
      .then(fetched => this.setState({ users: fetched }))
      .catch(err => {
        console.error(err);
        this.setState({ errorAlert: err.toString() });
        window.scrollTo(0, 0);
      });
  }

  componentDidMount() {
    this.fetchUsers();
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { discordID, permLevel, roles } = e.target;
    const rolesParsed = roles.value.split(",").map(role => role.trim()).filter(Boolean);
    const newUser = {
      discordID: discordID.value,
      permLevel: permLevel.value,
      roles: rolesParsed
    }
    try {
      const res = await fetch(`https://staging.api.qualityexpressvtc.eu/users`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newUser)
      });
      if (res.status !== 201) {
        this.setState({ errorAlert: `Error ${res.status}: ${res.statusText}` });
      } else {
        this.setState({ addedAlert: true })
        this.fetchUsers();
      }
    } catch (err) {
      console.error(err);
      this.setState({ errorAlert: err.toString() });
    }
  }

  handleEdit = async (e) => {
    e.preventDefault();
    const { ID, discordID, permLevel, roles } = e.target;
    const rolesParsed = roles.value.split(",").map(role => role.trim()).filter(Boolean);
    const editedData = {
      discordID: discordID.value,
      permLevel: permLevel.value,
      roles: rolesParsed
    }
    try {
      const res = await fetch(`https://staging.api.qualityexpressvtc.eu/users/${ID.value}`, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(editedData)
      });
      if (res.status !== 200) {
        this.setState({ errorAlert: `Error ${res.status}: ${res.statusText}` });
        window.scrollTo(0, 0);
      } else {
        this.setState({ editMode: null });
        this.fetchUsers();
      }
    } catch (err) {
      console.error(err);
      this.setState({ errorAlert: err.toString() });
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <Wrapper>
        <Page.Content title="VTC Administration • Drivers">
          {this.state.addedAlert && <Alert icon="check" type="success" isDismissible>User successfully added</Alert>}
          {this.state.errorAlert && <Alert icon="alert-triangle" type="danger" isDismissible>{this.state.errorAlert}</Alert>}
          <Card>
            <Form onSubmit={this.handleSubmit}>
              <Card.Header>
                <Card.Title>Add New Driver</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form.Input required name="discordID" label="Discord ID" placeholder="Discord account ID of the new user" />
                <Form.Group label="Permission Level">
                  <Form.Select name="permLevel">
                    <option value="driver">Driver</option>
                    {checkEditPerm("examiner") && <option value="examiner">Driving Skill Examiner</option>}
                    {checkEditPerm("HR") && <option value="eventM">Event Manager</option>}
                    {checkEditPerm("HR") && <option value="HR">Human Resources</option>}
                    {checkEditPerm("admin") && <option value="admin">Administrator</option>}
                  </Form.Select>
                </Form.Group>
                <Form.Input required name="roles" label="Roles" placeholder={`The user's position(s) in the company, separated by commas (e.g. Owner, CEO)`} />
              </Card.Body>
              <Card.Footer className="text-right">
                <Button color="primary" icon="plus" type="submit">Add</Button>
              </Card.Footer>
            </Form>
          </Card>
          <Card>
            <Card.Header>
              <Card.Title>Drivers List</Card.Title>
            </Card.Header>
            <Card.Body>
              <ConditionalWrapper condition={this.state.editMode !== null} wrapper={children => <Form onSubmit={this.handleEdit}>{children}</Form>}>
                <Table highlightRowOnHover>
                  <Table.Header>
                    <tr>
                      <Table.ColHeader>Discord ID</Table.ColHeader>
                      <Table.ColHeader>Username</Table.ColHeader>
                      <Table.ColHeader>Permission Level</Table.ColHeader>
                      <Table.ColHeader>Roles</Table.ColHeader>
                    </tr>
                  </Table.Header>
                  <Table.Body>
                    {
                      this.state.users.map(user => {
                        if (this.state.editMode === user._id) {
                          return (
                            <Table.Row key={user._id}>
                              <Table.Col><input type="hidden" name="ID" value={user._id} /><Form.Input required name="discordID" defaultValue={user.discordID} /></Table.Col>
                              <Table.Col><Form.Input readOnly name="username" defaultValue={user.username} /></Table.Col>
                              <Table.Col><Form.Select name="permLevel" defaultValue={user.permLevel}>
                                <option value="driver">Driver</option>
                                {checkEditPerm("examiner") && <option value="examiner">Driving Skill Examiner</option>}
                                {checkEditPerm("HR") && <option value="eventM">Event Manager</option>}
                                {checkEditPerm("HR") && <option value="HR">Human Resources</option>}
                                {checkEditPerm("admin") && <option value="admin">Administrator</option>}
                              </Form.Select></Table.Col>
                              <Table.Col><Form.Input required name="roles" defaultValue={user.roles.join(", ")} /></Table.Col>
                              <Table.Col>
                                {checkEditPerm(user.permLevel) && <Button color="primary" size="sm" icon="save" className="ml-1" type="submit" />}
                                {<Button color="secondary" size="sm" icon="x" className="ml-1" onClick={() => this.setState({ editMode: null })} />}
                              </Table.Col>

                            </Table.Row>
                          );
                        } else {
                          return (
                            <Table.Row key={user._id}>
                              <Table.Col>{user.discordID}</Table.Col>
                              <Table.Col>{user.username}</Table.Col>
                              <Table.Col>{
                                {
                                  'driver': "Driver",
                                  'examiner': "Driving Skill Examiner",
                                  'eventM': "Event Manager",
                                  'HR': "Human Resources",
                                  'admin': "Administrator",
                                  'superadmin': "Administrator"
                                }[user.permLevel]
                              }</Table.Col>
                              <Table.Col>{user.roles.join(", ")}</Table.Col>
                              <Table.Col>
                                {user.username && <Button color="secondary" size="sm" icon="eye" className="ml-1" RootComponent="a" href={`/users/${user._id}`} />}
                                {checkEditPerm(user.permLevel) && <Button type="button" color="primary" size="sm" icon="edit-2" className="ml-1" onClick={(e) => { e.preventDefault(); this.setState({ editMode: user._id }); }} />}
                                {checkEditPerm(user.permLevel) && <ConfirmButton color="danger" size="sm" icon="trash-2" className="ml-1" action={() => this.onUserRemove(user._id)} />}
                              </Table.Col>
                            </Table.Row>
                          );
                        }
                      })
                    }
                  </Table.Body>
                </Table>
              </ConditionalWrapper>
            </Card.Body>
          </Card>
        </Page.Content>
      </Wrapper>
    );
  }
}

export default AdminUsers;