import React from 'react';

import Wrapper from "../components/Wrapper";

import {
  Page,
  Card,
  Grid,
  Button,
  Tag
} from 'tabler-react';

import userUtils from '../services/userUtils';
import CopyButton from '../components/CopyButton';

const user = userUtils.getUser();

const secretCode = () => {
  alert("You found me! Congratulations!")
}

class Tracker extends React.Component {
  constructor() {
    super()
    this.state = {
      trackerVersion: null
    }
  }

  async componentDidMount() {
    try {
      const res = await fetch(`https://staging.api.qualityexpressvtc.eu/util/trackerVersion`, { credentials: 'include' });
      if (res.status !== 200) {
        this.setState({ trackerVersion: "Error" });
      } else {
        const fetched = await res.text();
        console.log(fetched)
        this.setState({ trackerVersion: fetched })
      }
    } catch (err) {
      console.error(err);
      this.setState({ trackerVersion: "Error" });
    }
  }

  render() {
    return (
      <Wrapper>
        <Page.Content title="Job Tracker">
          <Grid.Row cards>
            <Grid.Col width={12}>
              <Card>
                <Card.Header>
                  <Card.Title>Download</Card.Title>
                  {this.state.trackerVersion && <Tag className="ml-2">
                    {this.state.trackerVersion.match(/^\d/) ?
                      <><span className="text-green mr-1" onClick={secretCode}>•</span> v{this.state.trackerVersion}</> :
                      <><span className="text-red mr-1" onClick={secretCode}>•</span> {this.state.trackerVersion}</>}
                  </Tag>}
                </Card.Header>
                <Card.Body>
                  Here, you can download our automated ETS2 & ATS job tracker. Everything is packaged in a single installer,
                  so you don't have to do any extra configuration. Just run the downloaded file and follow through the installation.<br />
                  There is no need for a guide either, since everything is self-explanatory.<br /><br />
                  <u><h5>You will need the following information during installation:</h5></u>
                  <strong>Your Driver ID:</strong> <code>{user.id}</code> <CopyButton text={user.id} /><br />
                  <strong>Your TruckersMP Account ID:</strong> Go to your <a href="https://truckersmp.com/user">TruckersMP account page</a> and look for "TruckersMP ID" in Player Info under your username
                </Card.Body>
                <Card.Footer className="text-right">
                  <Button color="primary" icon="download"
                    RootComponent="a" href={`https://downloads.qualityexpressvtc.eu/tracker/${this.state.trackerVersion}.exe`}
                    disabled={!(this.state.trackerVersion && this.state.trackerVersion.match(/^\d/))} >Download</Button>
                </Card.Footer>
              </Card>
              <Card>
                <Card.Header>
                  <Card.Title>FAQ</Card.Title>
                </Card.Header>
                <Card.Body>
                  <h4>Why isn't "Playing Quality Express VTC" showing as my Discord status?</h4>
                  <h5>• I see "Playing TruckersMP" instead.</h5>
                  <p>
                    Since TruckersMP has their own Rich Presence (Discord status), you need to disable it.
                    You can do so in TruckersMP settings (cog wheel icon in the tab menu): turn off "Discord Rich Presence" and then the tracker will display as your status.
                  </p>
                  <h5 className="mt-3">• I see "Playing Euro Truck Simulator 2" instead.</h5>
                  <p>
                    Check if there's a Quality Express icon in the system tray (bottom right of the screen - for example Steam displays there too).
                    If not, start the tracker, without it running, no status will display. (You can launch it from the Start Menu, or desktop icon if you chose to create one.)
                  </p>
                  <h5 className="mt-3">• I see no status.</h5>
                  <p>
                    Firstly, is the game running? The Quality Express VTC status will only display while you're in-game.
                    Also make sure you have <i>"Display current activity as a status message"</i> enabled in Discord <i>Activity Privacy</i> settings.
                  </p>
                  <h4 className="mt-4">Why are my jobs not being logged?</h4>
                  <p>
                    Was the tracker running while you were delivering? If not, that's why. The tracker needs to be on all the time while you're in game to log jobs.
                    That's the exact reason auto-startup with Windows is a recommended option in the installer.<br />
                    If you're sure the tracker was running while you were playing, ask for help in the Discord.
                  </p>
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      </Wrapper>
    );
  }
}

export default Tracker;