import React from 'react';
import { Link } from 'react-router-dom';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

import {
  Page,
  Table,
  Button,
  Form
} from 'tabler-react';
import Wrapper from '../components/Wrapper';
import ErrorPage from './ErrorPage';
import ConfirmButton from '../components/ConfirmButton'
import getFormattedDate from '../utils/getFormattedDate';

import userUtils from '../services/userUtils';

class Events extends React.Component {
  constructor() {
    super();
    this.state = {
      convoys: [],
      loading: true,
      error: null
    }
  }

  onConvoyRemove = async (id) => {
    try {
      const res = await fetch(`https://staging.api.qualityexpressvtc.eu/events/${id}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      if (res.status !== 200) {
        this.setState({ error: `Error ${res.status}: ${res.statusText}` });
      } else {
        this.fetchConvoys();
      }
    } catch (err) {
      console.error(err);
      this.setState({ error: err.toString() });
    }
  }

  fetchConvoys = () => {
    fetch(`https://staging.api.qualityexpressvtc.eu/events`, { credentials: 'include' })
      .then(res => res.json())
      .then(fetched => this.setState({ convoys: fetched }))
      .catch(err => {
        console.error(err);
        this.setState({ error: err.toString() });
      });
  }

  onEventClick = ( eventClickInfo ) => {
    this.props.history.push(`/events/${eventClickInfo.event._def.publicId}`);
  }

  componentDidMount() {
    this.fetchConvoys();
  }

  render() {
    if (this.state.error) {
      return <ErrorPage err={this.state.error} />
    } else {
      return (
        <Wrapper>
          <Page.Content
            title="Events List"
            subTitle="All times are converted to your local timezone."
            options={(
              <Form.Switch label="Calendar View" onClick={() => this.setState({ calView: !this.state.calView })} />
            )}
          >
            {this.state.calView ? <FullCalendar
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              themeSystem="bootstrap"
              firstDay="1"
              events={this.state.convoys.map(convoy => {
                return {
                  id: convoy._id,
                  title: convoy.name,
                  date: convoy.convoyDate
                }
              })}
              eventTimeFormat={{ hour: "numeric", minute: "2-digit", meridiem: false }}
              eventClick={this.onEventClick}
            />
              : <Table
                highlightRowOnHover
              >
                <Table.Header>
                  <tr>
                    <Table.ColHeader>Convoy Date</Table.ColHeader>
                    <Table.ColHeader>Title</Table.ColHeader>
                    <Table.ColHeader>Route</Table.ColHeader>
                    <Table.ColHeader>Actions</Table.ColHeader>
                  </tr>
                </Table.Header>
                <Table.Body>
                  {
                    this.state.convoys.map(convoy => {
                      return <Table.Row key={convoy._id}>
                        <Table.Col>{getFormattedDate(new Date(convoy.convoyDate))}</Table.Col>
                        <Table.Col>{convoy.name}</Table.Col>
                        <Table.Col>{convoy.routeFrom + " - " + convoy.routeTo}</Table.Col>
                        <Table.Col>
                          <Link to={`/events/${convoy._id}`}><Button color="primary" size="sm">View</Button></Link>
                          {userUtils.checkPerm(["admin", "superadmin"]) && <ConfirmButton color="danger" size="sm" icon="trash-2" className="ml-1" action={() => this.onConvoyRemove(convoy._id)} />}
                        </Table.Col>
                      </Table.Row>
                    })
                  }
                </Table.Body>
              </Table>
            }
          </Page.Content>
        </Wrapper>
      )
    };
  };
}

export default Events;